<template>
  <div class="relative">
    <label v-if="label">{{ label }}</label>
    <select
      :id="id || 'select'"
      v-model="selected"
      name="location"
      :class="customSelectClass"
      @change="$emit('update:selectedItem',$event.target.value)"
      @blur="$emit('blur')"
    >
      <option
        value=""
        disabled
        v-if="placeholder"
      >
        {{ placeholder }}
      </option>
      <option
        v-for="option in items"
        :key="option.value"
        :value="option.value"
        class="text-gray-900"
      >
        {{ option.text }}
      </option>
    </select>
    <Text
      v-if="helpText && isError"
      :id="`${name}-description`"
      size="sm"
      :content="helpText"
      class="absolute left-0 bottom-0"
      :custom-class="isError ? 'text-red-600' : 'text-gray-500'"
    />
  </div>
</template>
<script>
/**
 - Native select used to display a list of items
 */
import Text from "../../../atoms/Text/Text.vue";

export default {
  name: "NativeSelect",
  components: {
    Text,
  },
  props: {
    /**
     * items to show in select
     */
    items: {
      type: Array,
      default: () => [],
    },
    /**
     * used to set predefined value.
     */
    selectedItem: {
      type: Object,
      default: () => {},
    },
    /**
     * label of select
     */
    label: {
      type: String,
      default: "",
    },
    /**
     * placeholder of select
     */
    placeholder: {
      type: String,
      default: "Select an option",
    },
    /**
     * extra class of label
     */
    customLabelClass: {
      type: String,
      default: "",
    },
    /**
     * extra class of select
     */
    customSelectClass: {
      type: String,
      default: "",
    },
    isError: {
      type: Boolean,
      default: false,
    },
    helpText: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    }
  },
  computed: {
    selected: {
      get() {
        return this.selectedItem || "";
      },
      set(newValue) {
        this.$emit("update:selectedItem", newValue);
      },
    },
  },
};
</script>
