<template>
    <HeaderSection v-bind="headerProps" />
    <SocialBox v-bind="socialProps" />
    <section class="hero-media">
		<Carousel :items="pages" />
	</section>
    <div class="content-wrap relative">
		<div class="entry entry-page relative">
			<h2 class="entry-title">{{ title }}</h2>
			<div class="entry-content">
                <div class="booking-form-full" id="event_form">
                    <Alert v-if="alertText" :text="alertText" />
                    <p id="date" class="field-checkin">
                        <div class="w-full flex gap-2">
                            <div class="flex-2">
                                <label>Event Start Date</label>
                                <DatePicker v-model="booking.startDate" >
                                    <template #default="{ togglePopover }">
                                        <div
                                            class="bg-white text-black border-gray w-full px-4 py-1.5 mb-5 flex items-center justify-between" style="border: 3px solid rgba(0, 0, 0, .05)"
                                            @click="togglePopover"
                                        >
                                            {{ moment(booking.startDate).format('MMMM DD, YYYY') }}
                                            <Icon name="CalendarIcon" size="5" isOutline color="black" hover-text-color="black"/>
                                        </div>
                                    </template>
                                </DatePicker>
                            </div>
                            <div class="flex-1 text-right">
                                <Select v-model:selectedItem="booking.startTime" id="start_time" label="Start Time" :items="timeOptions" placeholder="Select" />
                            </div>
                        </div>
                    </p>
                    <p class="field-checkout">
                        <div class="w-full flex gap-2">
                            <div class="flex-2">
                                <label>Event End Date</label>
                                <DatePicker v-model="booking.endDate" mode="dateTime">
                                    <template #default="{ togglePopover }">
                                        <div
                                            class="bg-white text-black border-gray w-full px-4 py-1.5 mb-5 flex items-center justify-between" style="border: 3px solid rgba(0, 0, 0, .05)"
                                            @click="togglePopover"
                                        >
                                            {{ moment(booking.endDate).format('MMMM DD, YYYY') }}
                                            <Icon name="CalendarIcon" size="5" isOutline color="black" hover-text-color="black"/>
                                        </div>
                                    </template>
                                </DatePicker>
                            </div>
                            <div class="flex-1 text-right">
                                <Select v-model:selectedItem="booking.endTime" id="end_time" label="End Time" :items="timeOptions" placeholder="Select" />
                            </div>
                        </div>
                        
                    </p>
                    <Text
                        v-if="booking.dateError"
                        size="sm"
                        :content="'Please select the valid start and end dates'"
                        custom-class="text-red-600 ml-2 -pt-10"
                    />
                    <p class="field-name">
                        <Select v-model:selectedItem="booking.setup_time" @blur="onValidate" id="setup_time" :isError="booking.setup_time_error" helpText="Please select one" label="How much time needed to setup?" :items="setup_times" placeholder="Select" />
                    </p>
                    <p class="field-adults">
                        <Select v-model:selectedItem="booking.event" @blur="onValidate" id="event" :isError="booking.eventError" helpText="Please select one" label="Type of Event" :items="events" placeholder="Select" />
                    </p>
                    <p class="field-children">
                        <Select v-model:selectedItem="booking.attendees" @blur="onValidate" id="attendees" :isError="booking.attendees_error" helpText="Please select one" label="Expected Number of Attendees" :items="attendeesNumbers" placeholder="Select" />
                    </p>
                    <p class="field-name">
                        <Inputs name="event_title" @blur="onValidate" :isError="booking.event_title_error" id="title" helpText="Please type the event title" label="Event Title (i.e. Vicky's Wedding)" placeholder="Event Title" type="text" v-model:value="booking.event_title" />
                    </p>
                    <p class="field-email">
                        <Inputs name="name" @blur="onValidate" :isError="booking.nameError" id="name" helpText="Please type contact name" label="Contact Name" placeholder="Your name and surname&hellip;" type="text" v-model:value="booking.name" />
                    </p>
                    <p class="field-email">
                        <Inputs name="email" @blur="onValidate" :isError="booking.emailError" id="email" helpText="Please type contact email" label="Contact Email" placeholder="Your email used when contacting you&hellip;" type="email" v-model:value="booking.email" />
                    </p>
                    <p class="field-room-type field-phone">
                        <Inputs name="phone" @blur="onValidate" :isError="booking.phoneError" id="phone" helpText="Please type contact phone" label="Contact Phone" inputMask="(###) ###-####" placeholder="Your Phone used when contacting you&hellip;" type="text" v-model:value="booking.phone" />
                    </p>
                    <p class="field-room-type field-phone">
                        <div class="w-full h-24"/>
                    </p>
                    <div class="w-max px-2 mt-4">
                        <label>Venue rental includes the following equipment. Unselect the items you don't need. The pricing may or may not affect the pricing.</label>
                        <div v-for="(equipment, index) in equipments" :key="index" class="flex items-start mb-2">
                            <div class="flex items-center h-5">
                                <input :id="equipment" type="checkbox" :value="equipment" v-model="booking.equipments" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800">
                            </div>
                            <label :for="equipment" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{equipment?.name}}</label>
                        </div>
                    </div>
                    <div class="w-max px-2 mt-2">
                        <label>I am interested in these additional services</label>
                        <div v-for="(service, index) in additionalServices" :key="index" class="flex items-start mb-2">
                            <div class="flex items-center h-5">
                                <input :id="service" type="checkbox" :value="service" v-model="booking.additionalService" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800">
                            </div>
                            <label :for="service" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{service?.name}}</label>
                        </div>
                    </div>
                    <div class="w-max px-2 mt-5">
                        <div v-for="state in depositStates" :key="state.value" class="flex items-center mb-2">
                            <input :id="state.value" type="radio" :value="state.value" name="deposit_state" v-model="booking.depositState" class="w-4 h-4 text-blue-600 border border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <label :for="state.value" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{state.label}}</label>
                        </div>
                    </div>
                    <Button @click="submit" :isLoading="isLoading" class="button-filled submit-btn bg-black text-white" size="size" variant="abc" content="Complete Booking"/>
                </div>
            </div>
        </div>
        <CopyRight v-bind="footerProps" />
    </div>
</template>

<script>
import HeaderSection from '../../organisms/Header/Header.vue';
import Alert from '../../atoms/Alert/Alert.vue';
import Inputs from '../../molecules/Inputs/Component/Component.vue';
import Select from '../../molecules/Selects/SimpleNative/SimpleNative.vue';
import Textarea from '../../molecules/Textareas/Simple/Simple.vue';
import Button from '../../atoms/Button/Button.vue';
import Carousel from '../../organisms/Carousel/Carousel.vue';
import SocialBox from "../../molecules/SocialBox/SocialBox.vue";
import CopyRight from '../../molecules/CopyRight/CopyRight.vue';
import { footerProps, timeOptions } from '../../constants';
import moment from 'moment';
import { DatePicker } from 'v-calendar';
import 'v-calendar/dist/style.css';
import Icon from '../../atoms/Icons/Icons.vue'
import Text from '../../atoms/Text/Text.vue'
/**
 * 
 */
export default {
    components: {
        HeaderSection,
        Alert,
        Inputs,
        Select,
        Textarea,
        Carousel,
        SocialBox,
        CopyRight,
        DatePicker,
        Icon,
        Button,
        Text,
    },
    props: {
        headerProps: {
            type: Object,
            default: () => null
        },
        socialProps: {
            type: Object,
            default: () => null
        },
        title: {
            type: String,
            default: () => ""
        },
        alertText: {
            type: String,
            default: () => ""
        },
        pages: {
            type: Array,
            default: () => []
        },
        startDate: {
            type: Object,
            default: () => {}
        },
        endDate: {
            type: Object,
            default: () => {}
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        products: {
            type: Array,
            default: () => []
        }
    },
    watch: {
        'booking.startDate': function (val) {
            this.booking.endDate = moment(val).add(1, 'days')
        },
        products: {
            handler(val) {
                console.log(val, 'val');
                this.equipments = val?.filter(p => p?.groups?.find(g => g?.name === 'INCLUDED' && g?.type === 'TAG'));
                this.booking.equipments = val?.filter(p => p?.groups?.find(g => g?.name === 'INCLUDED' && g?.type === 'TAG'));
                this.additionalServices = val?.filter(p => p?.groups?.find(g => g?.name === 'OPTIONAL' && g?.type === 'TAG'))
            },
            deep: true,
        }
    },
    data() {
        return {
            timeOptions,
            booking: { 
                name: null, 
                nameError: false,
                email: null, 
                emailError: false,
                phone: null, 
                phoneError: false,
                startDate: this.startDate, 
                startTime: "12:00 AM", 
                endDate: this.endDate, 
                endTime: "12:00 AM",
                dateError: false,
                event: null, 
                eventError: false,
                setup_time: 1, 
                setup_time_error: false,
                attendees: null,
                attendees_error: false, 
                event_title: null, 
                event_title_error: false,
                additionalService: [], 
                equipments: [],
                depositState: "soft_hold"
            },
            events: [
                {text: "Birthday Party",value: "Birthday Party"},
                {text: "Wedding",value: "Wedding"},
                {text: "Meeting",value: "Meeting"},
                {text: "Sweet 16",value:"Sweet 16"},
                {text:"Quincinera",value:"Quincinera"},
                {text: "General Event", value: "General Event"},
                {text: "Conference", value: "Conference"},
                {text: "Filming", value: "Filming"}
            ],
            attendeesNumbers: [
                {text: "1-100",value: "1-100"},
                {text: "200-300",value: "200-300"},
                {text: "300+",value: "300+"}
            ],
            roomTypes: [{text:'Single',value:'single'}, {text:'Double',value:'double'},{text:'Superior',value:'superior'},{text:'President',value:'president'}],
            rooms: [{text: 1,value: 1},{text: 2,value: 2},{text: 3,value: 3},{text: 4,value:4},{text:5,value:5}],
            additionalServices: [],
            equipments: [],
            setup_times: [
                {text: "1 Hours", value: 1},
                {text: "2 Hours", value: 2},
                {text: "3 Hours", value: 3},
                {text: "4 Hours", value: 4},
                {text: "5 Hours", value: 5},
                {text: "6 Hours", value: 6},
                {text: "7 Hours", value: 7},
                {text: "8 Hours", value: 8},
                {text: "9 Hours", value: 9},
                {text: "10 Hours", value: 10},
            ],
            depositStates: [
                {label: "I have toured the venue already and ready to put a deposit to hold my date(s)", value: "toured"},
                {label: "I would like to place a soft hold and schedule a tour before placing a deposit", value: "soft_hold"},
                {label: "I have not toured the venue, but I am ready to put a deposit to hold my date(s)", value: "not_toured"},
            ],
            moment,
            footerProps,
        } 
    },
    methods: {
        onValidate() {
            if (this.booking.event) {
                this.booking.eventError = false;
            }
            if (this.booking.setup_time) {
                this.booking.setup_time_error = false;
            }
            if (this.booking.attendees) {
                this.booking.attendees_error = false;
            }
            if (this.booking.event_title) {
                this.booking.event_title_error = false;
            }
            if (this.booking.name) {
                this.booking.nameError = false;
            }
            if (this.booking.email) {
                this.booking.emailError = false;
            }
            if (this.booking.phone) {
                this.booking.phoneError = false;
            }
        },
        submit() {
            if (moment(this.booking.startDate).format('YYYY-MM-DD') >= moment(this.booking.endDate).format('YYYY-MM-DD')) {
                this.booking.dateError = true;
                const element = document.getElementById('date');
                element.focus()
            } else {
                this.booking.dateError = false;
            }
            if (!this.booking.event) {
                this.booking.eventError = true;
                const element = document.getElementById('event');
                element.focus()
            } else {
                this.booking.eventError = false;
            }
            if (!this.booking.setup_time) {
                this.booking.setup_time_error = true;
                const element = document.getElementById('setup_time');
                element.focus()
            } else {
                this.booking.setup_time_error = false;
            }
            
            if (!this.booking.attendees) {
                this.booking.attendees_error = true;
                const element = document.getElementById('attendees');
                element.focus()
            } else {
                this.booking.attendees_error = false;
            }
            if (!this.booking.event_title) {
                this.booking.event_title_error = true;
                const element = document.getElementById('title');
                element.focus()
                window.scrollTo(0, 0)
            } else {
                this.booking.event_title_error = false;
            }
            if (!this.booking.name) {
                this.booking.nameError = true;
                const element = document.getElementById('name');
                element.focus()
            } else {
                this.booking.nameError = false;
            }
            if (!this.booking.email) {
                this.booking.emailError = true;
                const element = document.getElementById('email');
                element.focus()
            } else {
                this.booking.emailError = false;
            }
            if (!this.booking.phone) {
                this.booking.phoneError = true;
                const element = document.getElementById('phone');
                element.focus()
            } else {
                this.booking.phoneError = false;
            }

            if (!this.booking.event || !this.booking.name || !this.booking.email || !this.booking.phone || !this.booking.event_title || !this.booking.attendees || !this.booking.setup_time) {
                window.scrollTo(0, 0)
                return false;
            }

            const startDate = moment(this.booking.startDate).format('MMMM DD, YYYY') + " " + this.booking.startTime;
            const endDate = moment(this.booking.endDate).format('MMMM DD, YYYY') + " " + this.booking.endTime;
            this.$emit("submit", {
                ...this.booking,
                startDate: moment(startDate).toDate(),
                endDate: moment(endDate).toDate(),
            });
        }
    },
}
</script>

<style>
</style>