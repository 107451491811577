<template>
    <div class="message">
        <div class="alert">
            {{ text }}
        </div>
    </div>
 </template>
 
 <script>
 
 export default {
     props: {
        text: {
            type: String,
            default: () => '',
        }
     }
 };
 </script>
 