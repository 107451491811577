
<template>
  <div>
    <label
      for="comment"
    >{{
      label
    }}</label>
    <div class="mt-1">
      <textarea
        v-if="!isError"
        id="comment"
        v-model="inputValue"
        :rows="rows"
        :class="customClass"
        name="comment"
        :placeholder="placeholder"
      />
      <textarea
        v-else
        id="comment"
        v-model="inputValue"
        :rows="rows"
        :class="customClass"
        class="block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
        name="comment"
        aria-invalid="true"
        :aria-describedby="`comment-error`"
        :placeholder="placeholder"
      />
      <div
        v-if="isError"
        class="absolute inset-y-0 right-0 mt-1.5 pr-3 flex items-center pointer-events-none"
      >
        <Icons
          name="ExclamationCircleIcon"
          size="5"
          color="red-500"
        />
      </div>
    </div>
    <Text
      v-if="helpText && isError"
      :id="`comment-description`"
      size="sm"
      :content="helpText"
      class="absolute left-0 bottom-0"
      :custom-class="isError ? 'text-red-600' : 'text-gray-500'"
    />
  </div>
</template>
<script>
import Icons from "../../../atoms/Icons/Icons.vue";
import Text from "../../../atoms/Text/Text.vue";
/**
 * - Use it to show simple textarea
 */
export default {
  components: {
    Icons, Text
  },
  props: {
    /**
     * Use to show what textarea is for
     */
    label: {
      type: String,
      default: "",
    },
    isError: {
      type: Boolean,
      default: false,
    },
    helpText: {
      type: String,
      default: "",
    },
    /**
     * Placeholder of textarea
     */
    placeholder: {
      type: String,
      default: "",
    },
    /**
     * Use it to apply extra class to textarea
     */
    customClass: {
      type: String,
      default: "",
    },
    /**
     * Use to catch dynamic value of textarea
     */
    value: {
      type: String,
      default: "",
    },
    /**
     * 
    */
    rows: {
      type: Number,
      default: 4
    }
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("update:value", newValue);
      },
    },
  },
};
</script>
