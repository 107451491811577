<template>
  <BookingAccomodationTemplate
    v-bind="bookingProps"
    @submit="onSubmit"
    :isLoading="isLoading"
    :products="state.product.products"
  />
</template>

<script>
import BookingAccomodationTemplate from "@/components/templates/BookAccomodation/BookAccomodation";
import {
  headerProps,
  socialProps,
  accomodationBooking,
} from "@/components/constants";
import { Settings } from "../../settings.js";
import moment from "moment";
export default {
  components: {
    BookingAccomodationTemplate,
  },
  data() {
    return {
      bookingProps: null,
      isLoading: false,
    };
  },
  async created() {
    this.bookingProps = {
      headerProps: { ...headerProps, currentRoute: this.$route.name },
      socialProps: socialProps,
      title: accomodationBooking.title,
      alertText: "",
      pages: accomodationBooking.pages,
      startDate: moment(this.$route.query.startDate).set("hour", 13),
      endDate: moment(this.$route.query.endDate).set("hour", 13),
    };
    await this.actions.product.getProducts();
    console.log(this.state.product.products, "products");
  },
  methods: {
    async onSubmit(booking) {
      if (this.isLoading) return false;
      this.isLoading = true;
      console.log(booking, "booking");
      const events = await this.actions.event.getEvents({
        where: {
          isActive: true,
          days_some: {
            startDate_gte: new Date(booking.startDate),
            endDate_lte: new Date(booking.endDate),
          },
        },
        getValues: true,
      });
      console.log(events, "events");
      if (events?.length > 0) {
        this.actions.alert.showError({
          message:
            "There is already an actived event in your time range. Please try in another date.",
        });
        this.isLoading = false;
        return false;
      }
      const res = await this.actions.inventory.checkInventory({
        siteId: Settings.siteId,
        startDate: new Date(booking.startDate),
        endDate: new Date(booking.endDate),
      });
      if (res.checkInventory.isAvailable) {
        const createAppUser = await this.actions.createAppUser({
          email: booking.email,
          mobileNumber: booking.phone.replace(/[^A-Z0-9]/gi, ""),
          sendEmailVerification: false,
        });
        if (createAppUser?.user?.id) {
          await this.actions.user.updateUser({
            where: { id: createAppUser?.user?.id },
            data: {
              fullName: booking.name,
              firstName: booking?.name?.split(" ")?.[0],
              lastName: booking?.name?.split(" ")?.[1],
            },
          });
          const additionalServices = [], equipments = [];
          booking.additionalService?.map(s => additionalServices.push(s.id))
          booking.equipments?.map(s => equipments.push(s.id))
          const { saveEvent } = await this.actions.event.saveEvent({
            data: {
              creator: { connect: { id: createAppUser?.user?.id } },
              name: booking.event_title,
              // description: booking.event,
              type: "SPECIAL_EVENT",
              days: {
                create: [
                  {
                    name: booking.event_title + "_day",
                    startDate: new Date(booking.startDate),
                    endDate: new Date(booking.endDate),
                    type: "SPECIAL_EVENT",
                  },
                ],
              },
              metadata: {
                services: additionalServices,
                equipments: equipments,
                attendees: booking.attendees,
                type: booking.event,
                setup_time: booking.setup_time,
              },
              isActive: false,
            },
          });
          const { saveReservation } = await this.actions.user.saveReservation({
            data: {
              reservationDate: new Date(booking.startDate),
              event: { connect: { id: saveEvent?.id } },
              user: { connect: { id: createAppUser?.user?.id } },
              site: { connect: { id: Settings.siteId } },
              product: { connect: { id: Settings.productId } },
              status: "HOLD",
            },
          });
          console.log(saveReservation, "saveReservation");
          if (booking.depositState == "soft_hold") {
            // this.$router.push({name: 'Events', query: {uId: createAppUser?.user?.id}});
            this.$router.push({
              name: "Scheduling",
              query: { eventId: saveEvent?.id, uId: createAppUser?.user?.id },
            });
          } else {
            this.$router.push({
              name: "Booking Preview",
              query: {
                eId: saveEvent?.id,
                uId: createAppUser?.user?.id,
                rId: saveReservation?.id,
              },
            });
          }
        }
      } else {
        this.bookingProps.alertText = accomodationBooking.alertText;
      }
      this.isLoading = false;
    },
  },
  async mounted() {
    await this.actions.user.getUsers();
    console.log(this.state.user.users);
  },
};
</script>

<style scoped></style>
